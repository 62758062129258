<template>
    <div>
 <v-row justify="center" class="mt-6">
     <v-btn :to="{name:'caregiver'}" :disabled="$route.name =='caregiver'" color="success" class="mr-3"> Caregiver Biodata</v-btn> 
  
     <v-btn :to="{name:'client'}" :disabled="disabledClient" color="success" class="ml-3"> Client Biodata</v-btn>
 </v-row>


<v-main>
    <router-view></router-view>
</v-main>

    </div>
</template>

<script>
    export default {
        data() {
            return {
              
            }
        },
        computed: {
            disabledClient() {
                return this.$route.name == 'client' 
            }
        },

        methods: {
                
            }

        
    }
</script>

<style>
.fade-enter{
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.5s ease-out;
}

/* .fade-leave-to{
    opacity: ;
} */
</style>